/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("@rails/ujs").start()
require("turbolinks").start()
require("@nathanvda/cocoon")
import 'bootstrap/dist/js/bootstrap';
require('datatables.net-dt');
require("trix")
require("@rails/actiontext")
require("report_grades.js")
require("presentation_grades.js")

// Show our alerts and make them disappear
$(".alert:not(.holdalert)").delay(4000).slideUp(200, function() {
   $(this).alert('close');
});

// Make the Menu Work
var ready;
ready = function () {
  var hash = window.location.hash;
  hash && $('ul.nav a[href="' + hash + '"]').tab('show');
};
$(document).ready(ready);
$(document).on('page:load', ready);
