$(document).on('load turbolinks:load', function () {
   if($("#presentation-form").length) {
      // First we loop through everything on the form
      $('#presentation-form .save').each(function (_, element) {
         // And we create a listener for changes and save those
         $(element).change(function (e) {
            localStorage.setItem(window.location.pathname.replace(/\//g, "_") + "_" + e.target.id, e.target.value)
         })

         // And set set it from local storage if we have it.
         var value = localStorage.getItem(window.location.pathname.replace(/\//g, "_") + "_" + element.id)
         if (value) {
            $(element).val(value)
            $("#"+element.id+"_badge").text(value)
         } else {
            $("#"+element.id+"_badge").text($(element).val())
         }
      })

      // For our text areas, it's a bit more complex, but we get the instances of the Trix editors
      var trixPresentationFeedback = document.querySelector("#presentation_grade_feedback");

      // If we have local storage, we return the state of those
      if (window.location.pathname.replace(/\//g, "_") + "presentation_grade_feedback" in localStorage) {
         trixPresentationFeedback.editor.loadJSON(JSON.parse(localStorage.getItem(window.location.pathname.replace(/\//g, "_") + "presentation_grade_feedback")))
      }

      // And then we add event listeners to pickup changes and save those
      trixPresentationFeedback.addEventListener('trix-change', function(event) {
         localStorage.setItem(window.location.pathname.replace(/\//g, "_") + "presentation_grade_feedback", JSON.stringify(trixPresentationFeedback.editor))
      });

      // Finally, some code to show the value of the sliders when they change
      $('#presentation-form .save').each(function (_, element) {
         // And we create a listener for changes
         $(element).on('input', function (e) {
            // And we change the badge to the value when it changes.
            $("#"+e.target.id+"_badge").text(e.target.value)
         })
      })

      $( "#presentation-form" ).submit(function( event ) {
         localStorage.removeItem(window.location.pathname.replace(/\//g, "_") + "presentation_grade_feedback")
         $('#presentation-form .save').each(function (_, element) {
            localStorage.removeItem(window.location.pathname.replace(/\//g, "_") + "_" + element.id)
         })
      });
   }
})
