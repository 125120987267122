$(document).on('load turbolinks:load', function () {
   if($("#report-form").length) {
      // First we loop through everything on the form
      $('#report-form .save').each(function (_, element) {
         // And we create a listener for changes and save those
         $(element).change(function (e) {
            localStorage.setItem(window.location.pathname.replace(/\//g, "_") + "_" + e.target.id, e.target.value)
         })

         // And set set it from local storage if we have it.
         var value = localStorage.getItem(window.location.pathname.replace(/\//g, "_") + "_" + element.id)
         if (value) {
            $(element).val(value)
            $("#"+element.id+"_badge").text(value)
         } else {
            $("#"+element.id+"_badge").text($(element).val())
         }
      })

      // For our text areas, it's a bit more complex, but we get the instances of the Trix editors
      var trixReportPositive = document.querySelector("#report_grade_feedback_positive");
      var trixReportConstructive = document.querySelector("#report_grade_feedback_constructive");

      // If we have local storage, we return the state of those
      if (window.location.pathname.replace(/\//g, "_") + "report_grade_feedback_positive" in localStorage) {
         trixReportPositive.editor.loadJSON(JSON.parse(localStorage.getItem(window.location.pathname.replace(/\//g, "_") + "report_grade_feedback_positive")))
      }
      if (window.location.pathname.replace(/\//g, "_") + "report_grade_feedback_constructive" in localStorage) {
         trixReportConstructive.editor.loadJSON(JSON.parse(localStorage.getItem(window.location.pathname.replace(/\//g, "_") + "report_grade_feedback_constructive")))
      }

      // And then we add event listeners to pickup changes and save those
      trixReportPositive.addEventListener('trix-change', function(event) {
         localStorage.setItem(window.location.pathname.replace(/\//g, "_") + "report_grade_feedback_positive", JSON.stringify(trixReportPositive.editor))
      });
      trixReportConstructive.addEventListener('trix-change', function(event) {
         localStorage.setItem(window.location.pathname.replace(/\//g, "_") + "report_grade_feedback_constructive", JSON.stringify(trixReportConstructive.editor))
      });

      // Finally, some code to show the value of the sliders when they change
      $('#report-form .save').each(function (_, element) {
         // And we create a listener for changes
         $(element).on('input', function (e) {
            // And we change the badge to the value when it changes.
            $("#"+e.target.id+"_badge").text(e.target.value)
         })
      })

      $( "#report-form" ).submit(function( event ) {
         localStorage.removeItem(window.location.pathname.replace(/\//g, "_") + "report_grade_feedback_positive")
         localStorage.removeItem(window.location.pathname.replace(/\//g, "_") + "report_grade_feedback_constructive")
         $('#report-form .save').each(function (_, element) {
            localStorage.removeItem(window.location.pathname.replace(/\//g, "_") + "_" + element.id)
         })
      });
   }
})
